<script>
import Vue from 'vue'
import { AgGridVue } from 'ag-grid-vue'
import { PayrollResource } from '@sigmacloud/sigma-client/dist/resources/transactions/pr/index'
import RelatedResourceRenderer from '@sigmacloud/sigma-client/dist/components/ag-grid/RelatedResourceRenderer'
import CheckboxCellRenderer from '@sigmacloud/sigma-client/dist/components/ag-grid/CheckboxCellRenderer'
import { numericSumAggFunc } from '@sigmacloud/sigma-client/dist/util/ag-grid'
import SelectCellRenderer from '@sigmacloud/sigma-client/dist/components/ag-grid/SelectCellRenderer'

export default Vue.extend({
    name: 'PayrollDetailLineGrid',
    // For some reason, the Vue linter doesn't recognize AG Grid's use of the components
    // eslint-disable-next-line vue/no-unused-components
    components: { AgGridVue, RelatedResourceRenderer, CheckboxCellRenderer, SelectCellRenderer },
    props: {
        payroll: {
            type: PayrollResource,
            required: true,
        },
    },
    data() {
        return {
            rowData: [],
            saving: false,
            columnDefs: [
                {
                    headerName: 'Employee',
                    field: 'attributes.employee',
                    rowGroup: true,
                    rowGroupIndex: 1,
                    hide: true,
                    width: 350,
                    valueGetter: (params) => params.data.attributes.meta_name, //Need to account for new lines that do not have meta_name set
                },
                {
                    headerName: 'Type',
                    field: 'attributes.type',
                    rowGroup: true,
                    rowGroupIndex: 2,
                    sortable: false,
                    sort: 'desc',
                    width: 110,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) {
                            switch ((resource.attributes.type || '').toLowerCase()) {
                                case 'pay':
                                    return 'PAY'
                                case 'actualfeefee':
                                    return 'ACCRUAL'
                                default:
                                    return resource.attributes.type
                            }
                        }
                    },
                },
                {
                    headerName: 'Code',
                    field: 'element',
                    rowGroup: true,
                    rowGroupIndex: 4,
                    hide: false,
                    valueGetter: (params) => {
                        if (params.data && params.data.attributes) {
                            let { fee, fee_name, pay_code_name } = params.data.attributes

                            if (fee) return `${fee_name}`
                            return String(pay_code_name || fee_name || '')
                        }
                    },
                },
                {
                    headerName: 'Hours',
                    colId: 'hours_units',
                    aggFunc: (params) => {
                        let firstValue = params.values[0]
                        if (typeof firstValue === 'object') {
                            if (firstValue.resource.attributes.type === 'PAY') {
                                return params.values.reduce((totalHours, hours) => totalHours + parseFloat(hours.value), 0.0).toFixed(2)
                            }
                        } else if (typeof firstValue === 'string') {
                            let payHours = params.values.reduce((totalHours, hours) => totalHours + parseFloat(hours), 0.0)
                            return isNaN(payHours) ? undefined : payHours.toFixed(2)
                        }
                        return '0.00'
                    },
                    width: 80,
                    editable: (params) => !!params.data,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) {
                            return {
                                value: String(resource.attributes.hours_units || '0.00'),
                                resource,
                                toString() {
                                    return this.value
                                },
                            }
                        }
                        return ''
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        if (resource) {
                            // We are going to multiply rate * hours to get an amount
                            const hours = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(2)
                            const rate = parseFloat(resource.attributes.hourly_unit_rate || '0').toFixed(4)
                            const factor = parseFloat(resource.attributes.factor || '1.0').toFixed(4)
                            const amount = (parseFloat(rate) * parseFloat(hours) * parseFloat(factor)).toFixed(2)
                            resource.set('amount_decimal', amount)
                            resource.set('hours_units', hours)
                        }
                    },
                    valueFormatter: (params) => {
                        if (typeof params === 'object' && params.value) {
                            if (isNaN(parseFloat(params.value.value))) {
                                return undefined
                            }
                            return params.value.value
                        }
                    },
                },
                {
                    headerName: 'Work Date',
                    field: 'work_date_group',
                    rowGroup: true,
                    rowGroupIndex: 3,
                    width: 110,
                    editable: (params) => !!params.data,
                    valueGetter: (params) => {
                        if (!params.data) return ''
                        let resource = params.data
                        if (!resource || !resource.attributes) return
                        return resource && resource.attributes.work_date
                            ? String(resource.attributes.work_date)
                            : String(resource.attributes.pay_period_end_date)
                    },
                },
                {
                    headerName: 'Rate',
                    field: 'rate',
                    cellClass: 'rate',
                    aggFunc: 'first',
                    width: 100,
                    editable: (params) => !!params.data,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return String(resource.attributes.hourly_unit_rate || '')
                        return '-'
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        if (resource) {
                            // We are going to multiple rate * hours to get an amount
                            const rate = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(4)
                            const hours = parseFloat(resource.attributes.hours_units || '0').toFixed(2)
                            const factor = parseFloat(resource.attributes.factor || '1.0').toFixed(4)
                            const amount = (parseFloat(rate) * parseFloat(hours) * parseFloat(factor)).toFixed(2)
                            resource.set('hourly_unit_rate', rate)
                            if (parseFloat(amount) > 0) resource.set('amount_decimal', amount)
                        }
                    },
                },
                {
                    headerName: 'Override',
                    field: 'override',
                    cellRenderer: 'CheckboxCellRenderer',
                    showRowGroup: false,
                    width: 95,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return resource.attributes.override
                    },
                    valueSetter: (params) => {
                        if (params.node) {
                            let resource = params.data
                            if (resource && resource.attributes) {
                                resource.set('override', params.newValue)
                                params.newValue && params.newValue !== params.oldValue ? resource.set('status', 'EDITED') : resource.set('status', 'NONE')
                            }
                        }
                    },
                },
                {
                    headerName: 'Amount',
                    field: 'amount_decimal',
                    cellClass: 'amount',
                    aggFunc: (nodes) => {
                        // todo: refactor
                        let sum = 0
                        let { values } = nodes
                        if (values && values.length && values[0].value) {
                            for (let node of values) {
                                let { value } = node
                                if (value && value.allLeafChildren) {
                                    let leafChildren = value.allLeafChildren
                                    if (leafChildren.length && leafChildren[0].data) {
                                        for (let leafChild of leafChildren) {
                                            let resource = leafChild.data
                                            if (['PAY', 'FEE'].includes(resource.attributes.type)) {
                                                sum += parseFloat(resource.attributes.amount_decimal)
                                            }
                                        }
                                    }
                                } else if (value && node.type) {
                                    if (['PAY', 'FEE'].includes(node.type)) {
                                        sum += parseFloat(value)
                                    }
                                } else if (typeof node === 'string') {
                                    sum += parseFloat(node)
                                } else {
                                    return undefined
                                }
                            }
                        } else {
                            let tlSum = 0
                            for (let num of nodes.values) {
                                if (!isNaN(parseFloat(num))) {
                                    tlSum += parseFloat(num)
                                }
                            }
                            return tlSum.toFixed(2)
                        }
                        if (sum === 0) {
                            return undefined
                        } else if (typeof sum === 'number') {
                            return sum.toFixed(2)
                        }
                    },
                    width: 90,
                    editable: (params) => !!params.data,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (!params.node.group && resource && resource.attributes) {
                            return {
                                value: String(resource.attributes.amount_decimal || ''),
                                type: params.node.data.attributes.type,
                                toString: () => String(resource.attributes.amount_decimal || ''),
                            }
                        } else {
                            return {
                                value: params.node,
                                toString: () => '',
                            }
                        }
                    },
                    valueFormatter: (params) => {
                        if (typeof params.value === 'string') {
                            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value)
                        } else {
                            return params.value
                        }
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        if (resource) {
                            const amount = parseFloat(!params.newValue || isNaN(params.newValue) ? 0 : params.newValue).toFixed(2)
                            resource.set('amount_decimal', amount)
                        }
                    },
                },
                {
                    headerName: 'Pay',
                    field: 'pay',
                    width: 110,
                    aggFunc: numericSumAggFunc,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes.type === 'PAY') {
                            return String(resource.attributes.amount_decimal)
                        } else {
                            return undefined
                        }
                    },
                },
                {
                    headerName: 'ER Fee',
                    field: 'er_fee',
                    width: 110,
                    aggFunc: numericSumAggFunc,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && ['ERTAX', 'ERFEE'].includes(resource.attributes.type)) {
                            return String(resource.attributes.amount_decimal)
                        } else {
                            return undefined
                        }
                    },
                },
                {
                    headerName: 'EE DED',
                    field: 'ee_ded',
                    width: 110,
                    aggFunc: numericSumAggFunc,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && ['EETAX', 'DEDUCTION'].includes(resource.attributes.type)) {
                            return String(resource.attributes.amount_decimal)
                        } else {
                            return undefined
                        }
                    },
                },
                {
                    headerName: 'ER Cost',
                    field: 'er_cost',
                    width: 110,
                    aggFunc: numericSumAggFunc,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && ['PAY', 'ERTAX', 'ERFEE'].includes(resource.attributes.type)) {
                            return String(resource.attributes.amount_decimal)
                        } else {
                            return undefined
                        }
                    },
                },
                {
                    headerName: 'EE Net',
                    field: 'ee_net',
                    width: 110,
                    aggFunc: numericSumAggFunc,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource) {
                            let { type, amount_decimal } = resource.attributes
                            if (type === 'PAY') return String(amount_decimal)
                            if (['EETAX', 'DEDUCTION'].includes(type)) return String(-1 * parseFloat(amount_decimal))
                        }
                        return undefined
                    },
                },
                {
                    headerName: 'Work Address',
                    field: 'work_address',
                    aggFunc: 'first',
                    valueGetter: (params) => params.data.attributes.work_address,
                    cellRenderer: 'SelectCellRenderer',
                },
                {
                    headerName: 'Acct',
                    field: 'customer_budget_code',
                    width: 80,
                    aggFunc: 'first',
                    editable: (params) => !!params.data,
                    valueFormatter: (params) => params.value.replace('\uFF10', '0'),
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) {
                            let acctString = resource.attributes.customer_budget_code
                            if (acctString === undefined || acctString == null) {
                                acctString = ''
                            } else if (acctString.charAt(0) === '0') {
                                acctString = acctString.replace('0', '\uFF10')
                            }
                            return acctString
                        }
                        return ''
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        if (resource) {
                            let numericValue = String(params.newValue)
                            if (numericValue.charAt(0) == '\uFF10') {
                                numericValue = numericValue.replace('\uFF10', '0')
                            }
                            resource.set('customer_budget_code', numericValue)
                        }
                    },
                },
                {
                    headerName: 'Frng',
                    field: 'customer_fringe_code',
                    width: 80,
                    aggFunc: 'first',
                    editable: (params) => !!params.value,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return String(resource.attributes.customer_fringe_code || '')
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        if (resource) resource.set('customer_fringe_code', params.newValue)
                    },
                },
                {
                    headerName: 'HF',
                    field: 'customer_hf_code',
                    width: 80,
                    aggFunc: 'first',
                    editable: (params) => !!params.data,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return String(resource.attributes.customer_hf_code || '')
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        if (resource) resource.set('customer_hf_code', params.newValue)
                    },
                },
                {
                    headerName: 'Ser',
                    field: 'customer_series',
                    width: 80,
                    aggFunc: 'first',
                    editable: (params) => (params.data ? true : false),
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return String(resource.attributes.customer_series || '')
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        if (resource) resource.set('customer_series', params.newValue)
                    },
                },
                {
                    headerName: 'Loc',
                    field: 'customer_location',
                    width: 80,
                    aggFunc: 'first',
                    editable: (params) => !!params.data,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return String(resource.attributes.customer_location || '')
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        if (resource) resource.set('customer_location', params.newValue)
                    },
                },
                {
                    headerName: 'Set',
                    field: 'customer_set',
                    width: 80,
                    aggFunc: 'first',
                    editable: (params) => !!params.data,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return String(resource.attributes.customer_set || '')
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        if (resource) resource.set('customer_set', params.newValue)
                    },
                },

                {
                    headerName: 'FF1',
                    field: 'customer_ff1',
                    width: 80,
                    editable: (params) => !!params.data,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return String(resource.attributes.customer_ff1 || '')
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        if (resource) resource.set('customer_ff1', params.newValue)
                    },
                },

                {
                    headerName: 'FF2',
                    field: 'customer_ff2',
                    width: 80,
                    editable: (params) => !!params.data,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return String(resource.attributes.customer_ff2 || '')
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        if (resource) resource.set('customer_ff2', params.newValue)
                    },
                },
                {
                    headerName: 'Pen Hour',
                    field: 'pension_hours_units',
                    cellClass: 'amount',
                    width: 100,
                    editable: (params) => !!params.data,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return String(resource.attributes.pension_hours_units || '')
                    },
                    valueSetter: (params) => {
                        let resource = params.data
                        let formattedHrs = parseFloat(params.newValue).toFixed(2)
                        if (resource) resource.set('pension_hours_units', formattedHrs)
                    },
                },
                {
                    headerName: 'Sub Wage',
                    field: 'subject_wages_decimal',
                    cellClass: 'amount',
                    width: 100,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return String(resource.attributes.subject_wages_decimal || '')
                    },
                },

                {
                    headerName: 'Sub Hour',
                    field: 'subject_hours_units',
                    cellClass: 'amount',
                    width: 100,
                    valueGetter: (params) => {
                        let resource = params.data
                        if (resource && resource.attributes) return String(resource.attributes.subject_hours_units || '')
                    },
                },
            ],
            gridOptions: {
                defaultColDef: {
                    resizable: true,
                },
                suppressAggFuncInHeader: true,
            },
            gridApi: undefined,
            columnApi: undefined,
            groupingLevel: 1,
        }
    },
    async mounted() {
        await this.load()
    },
    methods: {
        async load() {
            let payrollDetailLines = await this.payroll.detailLines()
            // todo: Request additional pages
            await this.getPayCodes(payrollDetailLines.resources)
            this.rowData = payrollDetailLines.resources
        },
        async getPayCodes(lineResources) {
            for (let line of lineResources) {
                if (line.attributes.pay_code) {
                    try {
                        line.set('factor', await line.resolveAttribute('pay_code.factor'))
                    } catch (error) {
                        console.log('error')
                        throw error
                    }
                } else {
                    line.set('factor', 1.0)
                }
            }
        },
        async save() {
            if (!this.rowData || !this.rowData.length) return undefined
            this.saving = true

            let linesToSave = this.rowData.filter((line) => {
                let changeKeys = Object.keys(line.changes).filter((key) => !['override', 'factor'].includes(key))

                return !!changeKeys.length
            })

            // console.log(linesToSave)

            linesToSave.forEach(async (line) => {
                if (!line.attributes.work_address) {
                    let projectResource = await this.payroll.resolveAttribute('project')
                    let assignmentResource = await line.resolveAttribute('assignement')
                    if (projectResource.attributes.work_addresses.length) {
                        line.set('work_address', projectResource.attributes.work_addresses[0])
                    } else if (assignmentResource.attributes.address) {
                        line.set('work_address', assignmentResource.attributes.address)
                    } else {
                        line.set('work_address', 4)
                    }
                }
                if (!line.attributes.occ_code) {
                    let occCodeId = await line.resolveAttribute('assignment.occ_code.id')
                    line.set('occ_code', occCodeId)
                }
            })

            await Promise.all(linesToSave.map((line) => line.save()))
            this.saving = false
        },
        addLine(line) {
            this.rowData.push(line)
        },
        onGridReady(params) {
            if (params) {
                this.gridApi = params.api
                this.columnApi = params.columnApi
            }
        },
        changeGrouping(expand = true) {
            if (this.groupingLevel < 0) this.groupingLevel = 0

            this.gridApi.forEachNode((node) => {
                if (expand && node.level <= this.groupingLevel && ((node.level > 0 && node.group) || (node.data && node.data.attributes.type === 'PAY'))) {
                    node.setExpanded(true)
                } else if (expand === false) {
                    node.setExpanded(false)
                    if (node.level < this.groupingLevel - 1) {
                        node.setExpanded(true)
                    }
                }
            })
            if (expand === true) {
                this.groupingLevel++
            } else if (expand === false) {
                this.groupingLevel--
            }
        },
    },
})
</script>

<template>
    <div>
        <b-row class="mb-2 ml-1">
            <b-col>
                <b-button @click="changeGrouping"><b-icon icon="chevron-double-left"></b-icon></b-button
                ><b-button class="ml-1" @click="changeGrouping(false)"><b-icon icon="chevron-double-right"></b-icon></b-button>
            </b-col>
        </b-row>
        <ag-grid-vue
            style="width: 100%; height: 500px"
            class="ag-theme-balham"
            :column-defs="columnDefs"
            :row-data="rowData"
            :grid-options="gridOptions"
            @grid-ready="onGridReady" />
    </div>
</template>
