var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{directives:[{name:"page-title",rawName:"v-page-title",value:(_vm.pageTitle),expression:"pageTitle"}]},[(!_vm.displayAsModal)?_c('page-header',{attrs:{"title":_vm.pageTitle},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('router-link',{attrs:{"to":_vm.goToList}},[_vm._v(_vm._s(_vm.readableResourceClass))]),_vm._v(" | "+_vm._s(_vm.pageAction)+" ")]},proxy:true},{key:"actions-right",fn:function(){return [_c('b-dropdown',{staticClass:"ml-2 user-select-none",attrs:{"disabled":_vm.saving,"split":"","boundary":"viewport","right":""},on:{"click":_vm.saveAndContinue},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v("Save"),(_vm.saving)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()]},proxy:true}],null,false,1912748581)},[_c('b-dropdown-item',{on:{"click":_vm.saveAndAddAnother}},[_vm._v("Save and Add Another")]),_c('b-dropdown-item',{on:{"click":_vm.saveAndReturnToList}},[_vm._v("Save and Return to List")])],1)]},proxy:true}],null,false,850255546)}):_vm._e(),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('resource-card',{staticClass:"my-3",attrs:{"title":"Basic Information"}},[_c('resource-edit-form',{ref:"basic_info",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset(['code', 'name', 'description', 'currency', 'active']),"focus":"code","size":"sm","no-submit":""},on:{"openResource":_vm.openResource}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('resource-card',{staticClass:"my-3",attrs:{"title":"Accounts"}},[_c('resource-edit-form',{ref:"account",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset([
                                'default_markup_type',
                                'default_markup_amount_decimal',
                                'default_markup_rate',
                                'default_asset_inventory_account',
                                'default_cogs_account',
                                'default_sales_account',
                                'default_markup_account' ]),"size":"sm","no-submit":""},on:{"openResource":_vm.openResource}})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }