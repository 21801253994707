<script>
import BaseDetail from '../BaseDetail'

export default {
    name: 'AddressDetail',
    extends: BaseDetail,

    watch: {
        async 'resource.attributes.city'(newCity, oldCity) {
            if (newCity === oldCity) return

            await this.resource.resolveRelated()
            let city = await this.resource.get('city')
            this.resource.attributes.state = city && city.attributes ? city.attributes.state : undefined
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col>
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form
                            ref="main"
                            :resource="resource"
                            :formset="resource.getFormset()"
                            :focus="Object.keys(resource.getFormset())[0]"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
