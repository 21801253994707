<script>
import BaseDetail from '../BaseDetail'
export default {
    name: 'FeeDetail',
    extends: BaseDetail,

    computed: {
        formsToValidate() {
            return [
                this.$refs.basic_info,
                this.$refs.fee_classification,
                this.$refs.gl_billing,
                this.$refs.employee_matching,
                this.$refs.rate_function,
                this.$refs.exemptions,
                this.$refs.paycode_exclusions,
                this.$refs.limit_to,
                this.$refs.location,
                this.$refs.protected_net,
                this.$refs.additional_settings,
                this.$refs.actions,
            ]
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col md="6">
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form
                            ref="basic_info"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'code',
                                    'name',
                                    'description',
                                    'start_date',
                                    'end_date',
                                    'effective_date_matches_on',
                                    'symmetry_tax_id',
                                    'one_time',
                                    'actual_fee',
                                    'active',
                                ])
                            "
                            focus="code"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Fee Classification" class="my-3">
                        <resource-edit-form
                            ref="fee_classification"
                            :resource="resource"
                            :formset="resource.getFormset(['type', 'deduction_type', 'categories'])"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="GL & Billing" class="my-3">
                        <resource-edit-form
                            ref="gl_billing"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'billable_item',
                                    'can_edit_on_invoice',
                                    'can_delete_from_invoice',
                                    'account',
                                    'account_offset',
                                    'vendor',
                                    'credit_to_client',
                                    'visible_employee',
                                    'visible_client',
                                ])
                            "
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Employee Matching" class="my-3">
                        <resource-edit-form
                            ref="employee_matching"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'occ_codes',
                                    'employee_types',
                                    'wc_classification',
                                    'follows_employee',
                                    'labor_union_locals',
                                    'age_minimum',
                                    'age_maximum',
                                    'is_loanout',
                                ])
                            "
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="Rate Function & Min/Max" class="my-3">
                        <resource-edit-form
                            ref="rate_function"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'rate_function',
                                    'billed_rate_percentage',
                                    'billed_rate_fixed_amount_decimal',
                                    'minimum_fee_basis',
                                    'minimum_fee_amount_decimal',
                                    'lifetime_maximum_amount_decimal',
                                    'maximum_fee_basis',
                                    'maximum_subject_hours',
                                    'maximum_subject_wages_amount_decimal',
                                    'maximum_fee_amount_decimal',
                                    'flat_subject_hours',
                                    'round_to_nearest',
                                    'round_result',
                                    'round_result_method',
                                    'fee_accumulation_method',
                                    'always_apply_to_premium_dollars',
                                ])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Exemptions" class="my-3">
                        <resource-edit-form
                            ref="exemptions"
                            :resource="resource"
                            :formset="resource.getFormset(['futa_exempt', 'fit_exempt', 'sit_exempt', 'ss_exempt', 'medi_exempt', 'sdi_exempt', 'suta_exempt'])"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="Paycode Exclusions" class="my-3">
                        <resource-edit-form
                            ref="paycode_exclusions"
                            :resource="resource"
                            :formset="resource.getFormset(['excluded_paycodes', 'excluded_paycode_types'])"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Limit To" class="my-3">
                        <resource-edit-form
                            ref="limit_to"
                            :resource="resource"
                            :formset="resource.getFormset(['project', 'client'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="Location" class="my-3">
                        <resource-edit-form
                            ref="location"
                            :resource="resource"
                            :formset="resource.getFormset(['city', 'work_states', 'resident_states', 'hire_states'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Protected Net" class="my-3">
                        <resource-edit-form
                            ref="protected_net"
                            :resource="resource"
                            :formset="
                                resource.getFormset(['protected_net_calculation_method', 'protected_net_rate_percentage', 'protected_net_amount_decimal'])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="Additional Settings" class="my-3">
                        <resource-edit-form
                            ref="additional_settings"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'production_types',
                                    'schedules',
                                    'ptd_nptd',
                                    'format',
                                    'mpiphp_contribution_level',
                                    'priority',
                                    'tax_state',
                                ])
                            "
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Actions" class="my-3">
                        <resource-edit-form
                            ref="actions"
                            :resource="resource"
                            :formset="resource.getFormset(['overrides', 'trigger_fee'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
