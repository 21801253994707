<script>
import BaseDetail from '../BaseDetail'

export default {
    name: 'AccountsPayableDetail',
    extends: BaseDetail,

    computed: {
        formsToValidate() {
            return [this.$refs.formLeft, this.$refs.formRight, this.$refs.linesDetails]
        },
    },

    methods: {
        promisesToResolveAfterSave() {
            return [this.$refs.linesDetails.save()]
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col>
                    <resource-card title="Basic Information" class="my-3">
                        <b-row>
                            <b-col md="6">
                                <resource-edit-form
                                    ref="formLeft"
                                    :resource="resource"
                                    :formset="resource.getFormset(['number', 'vendor', 'amount_decimal', 'date'])"
                                    focus="vendor"
                                    size="sm"
                                    no-submit
                                    @openResource="openResource">
                                </resource-edit-form>
                            </b-col>
                            <b-col md="6">
                                <resource-edit-form
                                    ref="formRight"
                                    :resource="resource"
                                    :formset="resource.getFormset(['description', 'status'])"
                                    size="sm"
                                    no-submit />
                            </b-col>
                        </b-row>
                    </resource-card>
                    <resource-dist-lines-simple ref="linesDetails" :resource="resource" />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
