<script>
import BaseDetail from '../BaseDetail'
import { getAttributesFromRelatedToResource } from '@sigmacloud/sigma-client/dist/util/resources/attributes'
export default {
    name: 'ProjectDetail',
    extends: BaseDetail,
    data() {
        return {
            defaultOptions: {
                client: undefined,
            },
        }
    },
    computed: {
        formsToValidate() {
            return [
                this.$refs.basic_info,
                this.$refs.bank,
                this.$refs.billing,
                this.$refs.taxCutoffs,
                this.$refs.contact,
                this.$refs.project_dates,
                this.$refs.season,
                this.$refs.ptd_nptd,
                this.$refs.mpiphp,
                this.$refs.signatory,
                this.$refs.other,
                this.$refs.additional_settings,
                this.$refs.fees,
                this.$refs.fee_groups,
            ]
        },
    },
    watch: {
        async 'resource.attributes.client'() {
            if (this.resource.isNew()) {
                this.copyDataFromClient()
            }
        },
    },
    methods: {
        async copyDataFromClient() {
            let copyFields = [
                { from: 'client.bank', to: 'bank' },
                { from: 'client.default_payment_method', to: 'credit_type' },
                { from: 'client.default_payment_type', to: 'default_payment_type' },
                { from: 'client.ach_account_number', to: 'ach_account_number' },
                { from: 'client.ach_days', to: 'ach_days' },
                { from: 'client.ach_tense', to: 'ach_tense' },
                { from: 'client.mpiphp_employer_id', to: 'mpiphp_employer_id' },
                { from: 'client.apply_pr_tax_cutoffs_fica_ss', to: 'apply_pr_tax_cutoffs_fica_ss' },
                { from: 'client.apply_pr_tax_cutoffs_futa', to: 'apply_pr_tax_cutoffs_futa' },
                { from: 'client.apply_pr_tax_cutoffs_suta', to: 'apply_pr_tax_cutoffs_suta' },
                { from: 'client.apply_pr_tax_cutoffs_other', to: 'apply_pr_tax_cutoffs_other' },
                { from: 'client.bill_suta_on_resident_state', to: 'bill_suta_on_resident_state' },
                { from: 'client.apply_wc_cutoffs', to: 'apply_wc_cutoffs' },
                { from: 'client.bill_wc_on_premium_dollars', to: 'bill_wc_on_premium_dollars' },
                { from: 'client.bill_wc_on_allowances', to: 'bill_wc_on_allowances' },
                { from: 'client.bill_wc_on_resident_state', to: 'bill_wc_on_resident_state' },
                { from: 'client.bill_wc_on_hire_state', to: 'bill_wc_on_hire_state' },
                { from: 'client.address_main', to: 'address_main' },
                { from: 'client.address_billing', to: 'address_billing' },
                { from: 'client.address_mailing', to: 'address_mailing' },
                { from: 'client.address_contact', to: 'address_contact' },
                { from: 'client.industry', to: 'industry' },
                { from: 'client.wga_signatory_number', to: 'wga_signatory_number' },
                { from: 'client.sag_signatory_number', to: 'sag_signatory_number' },
                { from: 'client.dga_signatory_number', to: 'dga_signatory_number' },
            ]

            this.resource.attributes = await getAttributesFromRelatedToResource(this.resource, copyFields)
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col md="6">
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form
                            ref="basic_info"
                            :resource="resource"
                            :formset="
                                resource.getFormset(['name', 'number', 'client', 'commercial_job_number', 'description', 'meta_data', 'format', 'active'])
                            "
                            focus="name"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Bank" class="my-3">
                        <resource-edit-form
                            ref="bank"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'bank',
                                    'credit_type',
                                    'default_payment_type',
                                    'credit_term',
                                    'ach_account_number',
                                    'ach_routing_number',
                                    'ach_days',
                                    'ach_tense',
                                ])
                            "
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="Billing" class="my-3">
                        <resource-edit-form
                            ref="billing"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'pay_frequency',
                                    'pay_period_ending_day',
                                    'week_ending',
                                    'project_fee_accumulation_group',
                                    'fee_bundling',
                                    'allow_direct_deposit',
                                    'separate_allowance_checks',
                                    'hide_title_on_checks',
                                    'factored',
                                ])
                            "
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Tax And Billing Cutoffs" class="my-3">
                        <resource-edit-form
                            ref="taxCutoffs"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'apply_pr_tax_cutoffs_fica_ss',
                                    'apply_pr_tax_cutoffs_futa',
                                    'apply_pr_tax_cutoffs_suta',
                                    'apply_pr_tax_cutoffs_other',
                                    'bill_suta_on_resident_state',
                                    'apply_wc_cutoffs',
                                    'bill_wc_on_premium_dollars',
                                    'bill_wc_on_allowances',
                                    'bill_wc_on_resident_state',
                                    'bill_wc_on_hire_state',
                                ])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="Contact" class="my-3">
                        <resource-edit-form
                            ref="contact"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'address_main',
                                    'address_billing',
                                    'address_mailing',
                                    'address_contact',
                                    'address_production_office',
                                    'work_addresses',
                                    'address_project_secondaries',
                                    'printer_name',
                                    'printer_ip',
                                ])
                            "
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Project Dates" class="my-3">
                        <resource-edit-form
                            ref="project_dates"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'project_start_date',
                                    'project_end_date',
                                    'pre_production_start_date',
                                    'production_start_date',
                                    'post_production_start_date',
                                ])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                    <resource-card title="Season" class="my-3">
                        <resource-edit-form
                            ref="season"
                            :resource="resource"
                            :formset="resource.getFormset(['season', 'industry', 'production_type'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="4">
                    <resource-card title="PTD / NPTD" class="my-3">
                        <resource-edit-form
                            ref="ptd_nptd"
                            :resource="resource"
                            :formset="resource.getFormset(['ptd_nptd', 'contract_type', 'psa_agreement_signed_on'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="4">
                    <resource-card title="MPIPHP" class="my-3">
                        <resource-edit-form
                            ref="mpiphp"
                            :resource="resource"
                            :formset="resource.getFormset(['mpiphp_project_type', 'mpiphp_employer_id', 'mpiphp_picture_id'])"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                    <resource-card title="Signatory" class="my-3">
                        <resource-edit-form
                            ref="signatory"
                            :resource="resource"
                            :formset="resource.getFormset(['wga_signatory_number', 'sag_signatory_number', 'dga_signatory_number', 'sag_project_id'])"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
                <b-col md="4">
                    <resource-card title="Other" class="my-3">
                        <resource-edit-form ref="other" :resource="resource" :formset="resource.getFormset(['delivery_method', 'files'])" size="sm" no-submit />
                    </resource-card>
                </b-col>
                <b-col md="4">
                    <resource-card title="Additional Settings" class="my-3">
                        <resource-edit-form
                            ref="additional_settings"
                            :resource="resource"
                            :formset="resource.getFormset(['instance_name', 'apex_project_id', 'created_on', 'updated_on', 'closed_on'])"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <resource-card title="Fees & Fee Groups" class="my-3">
                        <b-container fluid>
                            <b-row>
                                <b-col md="6">
                                    <resource-card title="Fees" class="sub-card">
                                        <resource-edit-form ref="fees" :resource="resource" :formset="resource.getFormset(['fees'])" size="sm" no-submit />
                                    </resource-card>
                                </b-col>
                                <b-col md="6">
                                    <resource-card title="Fee Groups" class="sub-card">
                                        <resource-edit-form
                                            ref="fee_groups"
                                            :resource="resource"
                                            :formset="resource.getFormset(['fee_groups'])"
                                            size="sm"
                                            no-submit />
                                    </resource-card>
                                </b-col>
                            </b-row>
                        </b-container>
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
