<script>
import BaseDetail from '../BaseDetail'

export default {
    name: 'SignatureResource',
    extends: BaseDetail,

    data() {
        return {
            src: null,
            editingSignature: false,
            newSignature: false,
        }
    },
    computed: {
        getSrc() {
            return this.src
        },
    },
    watch: {
        async 'resource.attributes'() {
            await this.resource.resolveRelated()
            let img = await this.resource.get('base64_blob')
            if (img) {
                let aToB = window.atob(img)
                if (!aToB.includes('data:')) {
                    this.src = 'data:image/png;base64, ' + img
                } else {
                    this.src = window.atob(img)
                }
            }
        },
    },
    methods: {
        showEditModal() {
            this.editingSignature = true
        },
        editSignature() {
            let s = this.$refs.signaturePad.saveSignature()
            this.resource.attributes.base64_blob = window.btoa(s.data)
            this.src = s.data
            this.newSignature = false
        },
        discardSignature() {
            this.newSignature = false
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col md="6">
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form ref="main" :resource="resource" :formset="resource.getFormset(['name'])" focus="name" size="sm" no-submit />
                    </resource-card>
                </b-col>
                <b-col v-if="src" md="6"><img :src="getSrc" style="object-fit: contain" class="mt-4" height="100%" width="100%" /></b-col>
            </b-row>
            <b-row></b-row>
            <b-row> <b-button class="mt-5 mr-4 ml-auto" @click="showEditModal">New Signature</b-button> </b-row>
        </b-container>
        <b-modal v-model="editingSignature" :ok-disabled="!newSignature" title="Add Signature" size="xl" @ok="editSignature" @hide="discardSignature">
            <VueSignaturePad
                ref="signaturePad"
                style="border: black 1px solid"
                height="500px"
                width="100%"
                :options="{
                    onBegin: () => {
                        $refs.signaturePad.resizeCanvas() //https://stackoverflow.com/questions/55718297/why-doesnt-vue-signature-pad-work-in-a-modal
                        this.newSignature = true
                    },
                }" />
        </b-modal>
    </div>
</template>
