<script>
import BaseDetail from '../BaseDetail'
import { inputs } from '@sigmacloud/sigma-client/dist/components/formsets/inputs'

export default {
    name: 'ActualFeeRateDetail',
    extends: BaseDetail,

    computed: {
        formsToValidate() {
            return [this.$refs.basic_info, this.$refs.personal_info, this.$refs.permissions]
        },
        disabledPermissions: ({ resource }) => ({
            ...resource.getFormset(['is_staff', 'is_superuser', 'groups']),
            is_staff: new inputs.DefaultCheckbox('Staff Status', { disabled: true }),
            is_superuser: new inputs.DefaultCheckbox('Staff Status', { disabled: true }),
        }),
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-button :disabled="!resource.isNew()" @click="newResource">Reset</b-button>
                <b-dropdown disabled split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndReturnToList">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndContinue">Save and Continue Editing</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col md="12">
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form ref="basic_info" :resource="resource" :formset="resource.getFormset(['username'])" size="sm" no-submit />
                    </resource-card>
                </b-col>
                <b-col md="12">
                    <resource-card title="Personal Info" class="my-3">
                        <resource-edit-form
                            ref="personal_info"
                            :resource="resource"
                            :formset="resource.getFormset(['first_name', 'last_name', 'email'])"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <resource-card title="Permissions" class="my-3">
                        <resource-edit-form ref="permissions" :resource="resource" :formset="disabledPermissions" size="sm" no-submit />
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
