var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{directives:[{name:"page-title",rawName:"v-page-title",value:(_vm.pageTitle),expression:"pageTitle"}]},[(!_vm.displayAsModal)?_c('page-header',{attrs:{"title":_vm.pageTitle},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('router-link',{attrs:{"to":_vm.goToList}},[_vm._v(_vm._s(_vm.readableResourceClass))]),_vm._v(" | "+_vm._s(_vm.pageAction)+" ")]},proxy:true},{key:"actions-right",fn:function(){return [_c('b-dropdown',{staticClass:"ml-2 user-select-none",attrs:{"disabled":_vm.saving,"split":"","boundary":"viewport","right":""},on:{"click":_vm.saveAndContinue},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v("Save"),(_vm.saving)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()]},proxy:true}],null,false,1912748581)},[_c('b-dropdown-item',{on:{"click":_vm.saveAndAddAnother}},[_vm._v("Save and Add Another")]),_c('b-dropdown-item',{on:{"click":_vm.saveAndReturnToList}},[_vm._v("Save and Return to List")])],1)]},proxy:true}],null,false,850255546)}):_vm._e(),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('resource-card',{staticClass:"my-3",attrs:{"title":"Job"}},[_c('resource-edit-form',{ref:"job",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset([
                                'code',
                                'description',
                                'employee',
                                'project',
                                'occ_code',
                                'job_title_override',
                                'legislative_type',
                                'agreement',
                                'labor_union_local',
                                'contract_occ_code',
                                'department',
                                'schedule',
                                'employee_type',
                                'wc_classification',
                                'carries_wc' ]),"focus":"employee","size":"sm","no-submit":""},on:{"openResource":_vm.openResource}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('resource-card',{staticClass:"my-3",attrs:{"title":"Demographics"}},[_c('resource-edit-form',{ref:"demographics",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset(['eeo_class', 'start_date', 'term_date', 'aca_status']),"size":"sm","no-submit":""},on:{"openResource":_vm.openResource}})],1),_c('resource-card',{staticClass:"my-3",attrs:{"title":"Pay"}},[_c('resource-edit-form',{ref:"pay",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset([
                                'flsa_type',
                                'pay_rate',
                                'pay_rate_unit',
                                'hourly_pay_rate',
                                'scale_rate_decimal',
                                'currency',
                                'pay_frequency',
                                'payment_method' ]),"size":"sm","no-submit":""},on:{"openResource":_vm.openResource}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('resource-card',{staticClass:"my-3",attrs:{"title":"Fees & Fee Groups"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('resource-card',{staticClass:"sub-card",attrs:{"title":"Fees"}},[_c('resource-edit-form',{ref:"fees",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset(['fees']),"size":"sm","no-submit":""}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('resource-card',{staticClass:"sub-card",attrs:{"title":"Fee Groups"}},[_c('resource-edit-form',{ref:"fee_groups",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset(['fee_groups']),"size":"sm","no-submit":""}})],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}})],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('resource-card',{staticClass:"my-3",attrs:{"title":"Other Information"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('resource-edit-form',{ref:"other_1",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset(['customer_budget_code', 'customer_fringe_code']),"size":"sm","no-submit":""}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('resource-edit-form',{ref:"other_2",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset(['customer_hf_code', 'active']),"size":"sm","no-submit":""}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('resource-edit-form',{ref:"other_3",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset(['htg_id', 'apex_payroll_id']),"size":"sm","no-submit":""}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('resource-edit-form',{ref:"other_4",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset(['created_on', 'updated_on']),"size":"sm","no-submit":""}})],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }