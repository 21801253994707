<script>
import BaseDetail from '../BaseDetail'

export default {
    name: 'VendorDetail',
    extends: BaseDetail,

    data() {
        return {
            addressContactResource: null,
        }
    },

    computed: {
        formsToValidate: ({ $refs }) => [$refs.basic, $refs.tax, $refs.advanced],
        basicInformationFormSet: ({ resource }) => {
            const formset = resource.getFormset(['code', 'name', 'payee_name', 'default_payment_type', 'address_contact_id'])
            return resource.getFormset(
                resource.attributes.default_payment_type === 'ACH' ? [...Object.keys(formset), 'ach_routing_number', 'ach_account_number'] : formset
            )
        },
        vendorName: ({ resource }) => (resource ? resource.attributes.name : null),
    },

    watch: {
        vendorName(newName, oldName) {
            let { payee_name, tax_name } = this.resource.attributes
            if (!payee_name || payee_name === oldName) this.resource.attributes.payee_name = newName
            if (!tax_name || tax_name === oldName) this.resource.set('tax_name', newName)
        },
    },

    methods: {
        async loadData() {
            this.loading = true
            if (this.id) {
                await this.getResource()
            } else {
                let { code } = await this.$resources.VendorResource.nextAvailableCode()

                await this.newResource({
                    default_payment_type: 'CHECK',
                    entity_type: 'UNKNOWN',
                    code: code,
                })
            }
            this.loading = false
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col md="6">
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form
                            ref="basic"
                            :resource="resource"
                            :formset="basicInformationFormSet"
                            :focus="Object.keys(basicInformationFormSet)[0]"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                    <resource-card title="Tax Information" class="my-3">
                        <resource-edit-form
                            ref="tax"
                            :resource="resource"
                            :formset="resource.getFormset(['tax_name', 'entity_type', 'tax_id', 'w9', 'work_state'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Advanced Information" class="my-3">
                        <resource-edit-form
                            ref="advanced"
                            :resource="resource"
                            :formset="resource.getFormset(['parent'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
