<script>
import BaseDetail from '../BaseDetail'
import { inputs } from '@sigmacloud/sigma-client/dist/components/formsets/inputs'

export default {
    name: 'APISettingDetail',
    extends: BaseDetail,

    data() {
        return {
            defaultOptions: {
                value_type: undefined,
            },
        }
    },

    computed: {
        basicInformationFormSet() {
            return this.resource.attributes.value_type === 'BOOLEAN'
                ? { ...this.resource.getFormset(), value: new inputs.DefaultCheckbox('Value', { value: 'true', uncheckedValue: 'false', disabled: true }) }
                : this.resource.getFormset()
        },
    },

    watch: {
        'resource.attributes.value_type'(newType, oldType) {
            if (newType && oldType) {
                this.resource.attributes.value = ''
            }
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col>
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form
                            ref="main"
                            :resource="resource"
                            :formset="basicInformationFormSet"
                            :focus="Object.keys(basicInformationFormSet)[1]"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
