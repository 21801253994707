<script>
import BaseDetail from '../BaseDetail'

export default {
    name: 'ReportDetail',
    extends: BaseDetail,

    data() {
        return {
            base64PDF: undefined,
        }
    },

    computed: {
        readableResourceClass: () => 'Report',
    },

    methods: {
        async getResource(options = {}) {
            this.resource = await this.$resources[this.resourceClassName].detail(this.id, options)
            let result = await this.resource.body()
            this.base64PDF = result.body
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
        </page-header>
        <embed width="100%" height="750vh" :src="`data:application/pdf;base64,${base64PDF}`" />
    </div>
</template>
