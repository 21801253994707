<script>
import BaseDetail from '../BaseDetail'
export default {
    name: 'BudgetDetail',
    extends: BaseDetail,
    computed: {
        formsToValidate() {
            return [this.$refs.basic_info, this.$refs.company_info]
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col md="6">
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form
                            ref="basic_info"
                            :resource="resource"
                            :formset="resource.getFormset(['code', 'name', 'description', 'active'])"
                            focus="code"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="" class="my-3">
                        <resource-edit-form
                            ref="company_info"
                            :resource="resource"
                            :formset="resource.getFormset(['company', 'production', 'bank'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
