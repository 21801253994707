<script>
import { AssignmentResource, EEOClassResource, FLSATypeResource, LegislativeTypeResource } from '@sigmacloud/sigma-client/dist/resources'
import BaseDetail from '../BaseDetail'

export default {
    name: 'AssignmentDetail',
    extends: BaseDetail,

    computed: {
        formsToValidate() {
            return [
                this.$refs.job,
                this.$refs.demographics,
                this.$refs.pay,
                this.$refs.fees,
                this.$refs.fee_groups,
                // Uncomment when adding I9 form back.
                // this.$refs.i9_1,
                // this.$refs.i9_2,
                // this.$refs.i9_3,
                // this.$refs.i9_4,
                this.$refs.other_1,
                this.$refs.other_2,
                this.$refs.other_3,
                this.$refs.other_4,
            ]
        },
    },

    async mounted() {
        this.setDefaultFromCode('legislative_type', await LegislativeTypeResource.all(), '999')
        this.setDefaultFromCode('eeo_class', await EEOClassResource.all(), '5')
        this.setDefaultFromCode('flsa_type', await FLSATypeResource.all(), 'N')
    },

    methods: {
        setDefaultFromCode(resourceName, resourceArray, code) {
            AssignmentResource.defaults[resourceName] = resourceArray.find((resource) => resource.attributes.code === code).id
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col md="6">
                    <resource-card title="Job" class="my-3">
                        <resource-edit-form
                            ref="job"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'code',
                                    'description',
                                    'employee',
                                    'project',
                                    'occ_code',
                                    'job_title_override',
                                    'legislative_type',
                                    'agreement',
                                    'labor_union_local',
                                    'contract_occ_code',
                                    'department',
                                    'schedule',
                                    'employee_type',
                                    'wc_classification',
                                    'carries_wc',
                                ])
                            "
                            focus="employee"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Demographics" class="my-3">
                        <resource-edit-form
                            ref="demographics"
                            :resource="resource"
                            :formset="resource.getFormset(['eeo_class', 'start_date', 'term_date', 'aca_status'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                    <resource-card title="Pay" class="my-3">
                        <resource-edit-form
                            ref="pay"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'flsa_type',
                                    'pay_rate',
                                    'pay_rate_unit',
                                    'hourly_pay_rate',
                                    'scale_rate_decimal',
                                    'currency',
                                    'pay_frequency',
                                    'payment_method',
                                ])
                            "
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <resource-card title="Fees &amp; Fee Groups" class="my-3">
                        <b-container fluid>
                            <b-row>
                                <b-col md="6">
                                    <resource-card title="Fees" class="sub-card">
                                        <resource-edit-form ref="fees" :resource="resource" :formset="resource.getFormset(['fees'])" size="sm" no-submit />
                                    </resource-card>
                                </b-col>
                                <b-col md="6">
                                    <resource-card title="Fee Groups" class="sub-card">
                                        <resource-edit-form
                                            ref="fee_groups"
                                            :resource="resource"
                                            :formset="resource.getFormset(['fee_groups'])"
                                            size="sm"
                                            no-submit />
                                    </resource-card>
                                </b-col>
                            </b-row>
                        </b-container>
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <!-- Commented out by request
                        <resource-card title="I9 Verification" class="my-3">
                            <b-container fluid>
                                <b-row>
                                    <b-col md="12">
                                        <resource-edit-form
                                            ref="i9_1"
                                            :resource="resource"
                                            :formset="resource.getFormset(['i9_rehire', 'i9_verification_date', 'hire_state'])"
                                            size="sm"
                                            no-submit
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="4">
                                        <resource-card title="Type A" class="sub-card">
                                            <resource-edit-form
                                                ref="i9_2"
                                                :resource="resource"
                                                :formset="
                                                    resource.getFormset(['i9_list_a_type', 'i9_list_a_number', 'i9_list_a_authority', 'i9_list_a_expiration'])
                                                "
                                                size="sm"
                                                no-submit
                                            />
                                        </resource-card>
                                    </b-col>
                                    <b-col md="4">
                                        <resource-card title="Type B" class="sub-card">
                                            <resource-edit-form
                                                ref="i9_3"
                                                :resource="resource"
                                                :formset="
                                                    resource.getFormset(['i9_list_b_type', 'i9_list_b_number', 'i9_list_b_authority', 'i9_list_b_expiration'])
                                                "
                                                size="sm"
                                                no-submit
                                            />
                                        </resource-card>
                                    </b-col>
                                    <b-col md="4">
                                        <resource-card title="Type C" class="sub-card">
                                            <resource-edit-form
                                                ref="i9_4"
                                                :resource="resource"
                                                :formset="
                                                    resource.getFormset(['i9_list_c_type', 'i9_list_c_number', 'i9_list_c_authority', 'i9_list_c_expiration'])
                                                "
                                                size="sm"
                                                no-submit
                                            />
                                        </resource-card>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </resource-card>
                    -->
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <resource-card title="Other Information" class="my-3">
                        <b-container fluid>
                            <b-row>
                                <b-col md="3">
                                    <resource-edit-form
                                        ref="other_1"
                                        :resource="resource"
                                        :formset="resource.getFormset(['customer_budget_code', 'customer_fringe_code'])"
                                        size="sm"
                                        no-submit />
                                </b-col>
                                <b-col md="3">
                                    <resource-edit-form
                                        ref="other_2"
                                        :resource="resource"
                                        :formset="resource.getFormset(['customer_hf_code', 'active'])"
                                        size="sm"
                                        no-submit />
                                </b-col>
                                <b-col md="3">
                                    <resource-edit-form
                                        ref="other_3"
                                        :resource="resource"
                                        :formset="resource.getFormset(['htg_id', 'apex_payroll_id'])"
                                        size="sm"
                                        no-submit />
                                </b-col>
                                <b-col md="3">
                                    <resource-edit-form
                                        ref="other_4"
                                        :resource="resource"
                                        :formset="resource.getFormset(['created_on', 'updated_on'])"
                                        size="sm"
                                        no-submit />
                                </b-col>
                            </b-row>
                        </b-container>
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
