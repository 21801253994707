<script>
import BaseDetail from '../BaseDetail'

export default {
    name: 'EmployeeDetail',
    extends: BaseDetail,

    data() {
        return {
            formsetKey: 0,
            w4SearchLink: `https://${this.instanceName}.sigmacloud.io/admin/system/w4/?employee=${this.id}`,
            w4ListLink: `https://${this.instanceName}.sigmacloud.io/admin/system/w4/`,
            directDepositSearchLink: `https://${this.instanceName}.sigmacloud.io/admin/system/depositaccount/?employee=${this.id}`,
            directDepositCreateLink: `https://${this.instanceName}.sigmacloud.io/admin/system/depositaccount/add`,
            directDepositListLink: `https://${this.instanceName}.sigmacloud.io/admin/system/depositaccount/`,
        }
    },

    computed: {
        formsToValidate() {
            return [
                this.$refs.employee_1,
                this.$refs.employee_2,
                this.$refs.addresses,
                this.$refs.contact,
                this.$refs.emergency_contact,
                this.$refs.loanout,
                this.$refs.tax_1,
                this.$refs.tax_2,
                this.$refs.tax_3,
                this.$refs.tax_4,
                this.$refs.other_1,
                this.$refs.other_2,
                this.$refs.other_3,
                this.$refs.other_4,
            ]
        },
    },
    watch: {
        '$data.resource.attributes.is_loanout'(newValue) {
            if (newValue === true) {
                this.$data.resource.attributes.exempt_fit = true
                this.$data.resource.attributes.exempt_sit = true
                this.$data.resource.attributes.exempt_fica = true
                this.$data.resource.attributes.exempt_medicare = true
                this.$data.resource.attributes.exempt_futa = true
                this.$data.resource.attributes.exempt_suta = true
                this.$data.resource.attributes.exempt_sdi = true
                this.formsetKey++
            }
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col>
                    <resource-card title="Basic Information" class="my-3">
                        <b-row>
                            <b-col md="6">
                                <resource-edit-form
                                    ref="employee_1"
                                    :resource="resource"
                                    :formset="resource.getFormset(['tin', 'last_name', 'first_name', 'middle_name', 'name_suffix', 'alt_name'])"
                                    :focus="$router.history.current.path.includes('create') ? 'tin' : 'last_name'"
                                    size="sm"
                                    no-submit />
                            </b-col>
                            <b-col md="6">
                                <resource-edit-form
                                    ref="employee_2"
                                    :resource="resource"
                                    :formset="resource.getFormset(['birth_date', 'citizenship', 'ethnicity', 'gender', 'payment_method', 'id'])"
                                    size="sm"
                                    no-submit />
                            </b-col>
                        </b-row>
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <resource-card title="Addresses" class="my-3">
                        <resource-edit-form
                            ref="addresses"
                            :resource="resource"
                            :formset="resource.getFormset(['address_residence', 'address_mailing', 'address_w2'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <resource-card title="Contact" class="my-3">
                        <b-row>
                            <b-col>
                                <resource-edit-form
                                    ref="contact"
                                    :resource="resource"
                                    :formset="resource.getFormset(['phone_number', 'email'])"
                                    size="sm"
                                    no-submit
                                    @openResource="openResource" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <resource-card title="Emergency Contact" class="sub-card">
                                    <resource-edit-form
                                        ref="emergency_contact"
                                        :resource="resource"
                                        :formset="resource.getFormset(['emergency_contact_name', 'emergency_contact_phone_number', 'emergency_contact_email'])"
                                        size="sm"
                                        no-submit
                                        @openResource="openResource" />
                                </resource-card>
                            </b-col>
                        </b-row>
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <resource-card title="Loanout" class="my-3">
                        <resource-edit-form
                            ref="loanout"
                            :resource="resource"
                            :formset="resource.getFormset(['is_loanout', 'loanout_name', 'fein', 'loanout_states_registered'])"
                            size="sm"
                            no-submit />
                    </resource-card>
                    <resource-card :key="formsetKey" title="Tax" class="my-3">
                        <b-container fluid>
                            <b-row>
                                <b-col md="2">
                                    <resource-edit-form
                                        ref="tax_1"
                                        :resource="resource"
                                        :formset="resource.getFormset(['exempt_fit', 'exempt_sit'])"
                                        size="sm"
                                        no-submit />
                                </b-col>
                                <b-col md="2">
                                    <resource-edit-form
                                        ref="tax_2"
                                        :resource="resource"
                                        :formset="resource.getFormset(['exempt_futa', 'exempt_suta'])"
                                        size="sm"
                                        no-submit />
                                </b-col>
                                <b-col md="2">
                                    <resource-edit-form
                                        ref="tax_3"
                                        :resource="resource"
                                        :formset="resource.getFormset(['exempt_medicare', 'exempt_fica'])"
                                        size="sm"
                                        no-submit />
                                </b-col>
                                <b-col md="2">
                                    <resource-edit-form ref="tax_4" :resource="resource" :formset="resource.getFormset(['exempt_sdi'])" size="sm" no-submit />
                                </b-col>
                                <b-col md="2">
                                    <resource-edit-form
                                        ref="tax_4"
                                        :resource="resource"
                                        :formset="resource.getFormset(['received_8233', 'received_w8_ben'])"
                                        size="sm"
                                        no-submit />
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <resource-card title="W-4" class="sub-card">
                                        <a :href="w4SearchLink" rel="noreferrer noopener" target="_blank" class="mx-1">
                                            <b-button
                                                variant="primary"
                                                :disabled="!resource.attributes.id"
                                                :title="!resource.attributes.id ? 'Employee must be saved' : 'Find W-4s associated with this employee'"
                                                :style="!resource.attributes.id && 'cursor: help'"
                                                >Search
                                            </b-button>
                                        </a>
                                        <a :href="w4ListLink" rel="noreferrer noopener" target="_blank" class="mx-1">
                                            <b-button
                                                variant="primary"
                                                :disabled="!resource.attributes.id"
                                                :title="!resource.attributes.id ? 'Employee must be saved' : 'List all W-4s'"
                                                :style="!resource.attributes.id && 'cursor: help'"
                                                >List
                                            </b-button>
                                        </a>
                                    </resource-card>
                                </b-col>
                            </b-row>

                            <!--

                                Below is commented out until W-4s are working in V2. Until then we will link to the admin panel.


                            <b-row>
                                <b-col>
                                    <resource-card title="Federal W-4" class="sub-card"></resource-card>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <resource-card title="State W-4" class="sub-card"></resource-card>
                                </b-col>
                            </b-row>
                            
                            -->
                        </b-container>
                    </resource-card>
                    <b-row>
                        <b-col>
                            <resource-card title="Assignments" class="my-3">
                                <resource-edit-form
                                    ref="assignments"
                                    :resource="resource"
                                    :formset="resource.getFormset(['assignments'])"
                                    size="sm"
                                    no-submit
                                    @openResource="openResource" />
                            </resource-card>
                        </b-col>
                        <b-col>
                            <resource-card :key="formsetKey" title="Direct Deposit" class="my-3">
                                <b-container fluid>
                                    <b-row>
                                        <b-col>
                                            <a :href="directDepositSearchLink" rel="noreferrer noopener" target="_blank" class="mx-1">
                                                <b-button
                                                    variant="primary"
                                                    :disabled="!resource.attributes.id"
                                                    :title="
                                                        !resource.attributes.id
                                                            ? 'Employee must be saved'
                                                            : 'Find direct deposits associated with this employee'
                                                    "
                                                    :style="!resource.attributes.id && 'cursor: help'"
                                                    >Search
                                                </b-button>
                                            </a>
                                            <a :href="directDepositListLink" rel="noreferrer noopener" target="_blank" class="mx-1">
                                                <b-button
                                                    variant="primary"
                                                    :disabled="!resource.attributes.id"
                                                    :title="!resource.attributes.id ? 'Employee must be saved' : 'List all direct deposits'"
                                                    :style="!resource.attributes.id && 'cursor: help'"
                                                    >List
                                                </b-button>
                                            </a>
                                            <a :href="directDepositCreateLink" rel="noreferrer noopener" target="_blank" class="mx-1">
                                                <b-button
                                                    variant="primary"
                                                    :disabled="!resource.attributes.id"
                                                    :title="!resource.attributes.id ? 'Employee must be saved' : 'Create new direct deposit'"
                                                    :style="!resource.attributes.id && 'cursor: help'"
                                                    >Create
                                                </b-button>
                                            </a>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </resource-card>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="Fees" class="my-3">
                        <resource-edit-form
                            ref="fees"
                            :resource="resource"
                            :formset="resource.getFormset(['fees'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Fee Groups" class="my-3">
                        <resource-edit-form ref="feegroups" :resource="resource" :formset="resource.getFormset(['fee_groups'])" size="sm" no-submit />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <resource-card title="Other" class="my-3">
                        <b-container fluid>
                            <b-row>
                                <b-col md="3">
                                    <resource-edit-form
                                        ref="other_1"
                                        :resource="resource"
                                        :formset="resource.getFormset(['agent', 'deceased_date'])"
                                        size="sm"
                                        no-submit />
                                </b-col>
                                <b-col md="3">
                                    <resource-edit-form ref="other_2" :resource="resource" :formset="resource.getFormset(['active'])" size="sm" no-submit />
                                </b-col>
                                <b-col md="3">
                                    <resource-edit-form
                                        ref="other_3"
                                        :resource="resource"
                                        :formset="resource.getFormset(['htg_id', 'apex_payroll_id'])"
                                        size="sm"
                                        no-submit />
                                </b-col>
                                <b-col md="3">
                                    <resource-edit-form
                                        ref="other_4"
                                        :resource="resource"
                                        :formset="resource.getFormset(['created_on', 'updated_on'])"
                                        size="sm"
                                        no-submit />
                                </b-col>
                            </b-row>
                        </b-container>
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
