<script>
import Vue from 'vue'
import BaseDetail from '../BaseDetail.vue'
import { AddressResource } from '@sigmacloud/sigma-client/dist/resources'
import { EnumeratedInput, EnumeratedInputOption } from '@sigmacloud/sigma-client/dist/components/formsets/inputs'
import PayrollDetailLineGrid from './PayrollDetailLineGrid.vue'
import PayrollDetailLineResource from '@sigmacloud/sigma-client/dist/resources/transactions/pr/payrolldetailline'
import { extendValidators, isRequired } from '@sigmacloud/sigma-client/dist/util/resources/validation'

export default Vue.extend({
    name: 'PayrollDetail',
    components: { PayrollDetailLineGrid },
    extends: BaseDetail,
    data() {
        return {
            calculating: false,
            defaultOptions: {
                project: undefined,
            },
            prDetailLineResource: undefined,
            addressOptions: [],
            showPrFeeModal: false,
            validationMessages: [],
            selectedAssignments: [],
        }
    },
    computed: {
        formsToValidate: ({ $refs }) => [$refs.left_info, $refs.right_info],
        leftFormset: ({ resource, addressOptions }) => ({
            ...resource.getFormset(['description', 'status', 'project', 'department', 'date', 'pay_period_start_date', 'pay_period_end_date']),
            state: new EnumeratedInput('Work Address', addressOptions),
        }),
        rightFormset: ({ resource }) => ({
            ...resource.getFormset(['commercial_job_number', 'invoice_instructions', 'excluded_fees', 'batch', 'date_due', 'check_date']),
        }),
        prFeeFormset: () => {
            return PayrollDetailLineResource.getFormset(['fee', 'amount_decimal', 'transaction_date', 'customer_budget_code'])
        },
    },
    watch: {
        'resource.attributes.project'(project) {
            this.getProjectWorkAddresses(project)
        },
        calculating(newVal) {
            this.$refs.calcButton.disabled = newVal
        },
    },
    methods: {
        async getProjectWorkAddresses() {
            let workAddressesIds = await this.resource.resolveAttribute('project.work_addresses')
            let workAddressResources = await Promise.all(workAddressesIds.value.map((addressId) => AddressResource.detail(addressId)))
            await Promise.all(workAddressResources.map((address) => address.resolveRelated({ managers: ['city', 'state'] })))

            this.addressOptions = workAddressResources.map(
                (workAddress) =>
                    new EnumeratedInputOption(
                        workAddress.attributes.state,
                        `${workAddress.attributes.line1}, ${workAddress.get('city.name')}, ${workAddress.get('state.code')}`
                    )
            )
        },
        promisesToResolveAfterSave() {
            return [this.$refs.payrollDetailLines.save()]
        },
        showAddFee() {
            this.prDetailLineResource = new PayrollDetailFeeLineResource({
                transaction_id: this.resource.id,
                status: 'ADDED',
                work_date: this.resource.attributes.pay_period_end_date,
            })
            this.showPrFeeModal = true
        },
        async saveNewPrFee(event) {
            let validationResponse = this.prDetailLineResource.validate()
            this.$refs.addFee.validate()
            if (validationResponse.length > 0) {
                event.preventDefault()
                return undefined
            }
            let feeLineType = await this.prDetailLineResource.resolveAttribute('fee.line_type')
            if (feeLineType) {
                this.prDetailLineResource.set('type', feeLineType)
            } else {
                this.prDetailLineResource.set('type', await this.prDetailLineResource.resolveAttribute('fee.type'))
            }
            let projectResource = await this.resource.resolveAttribute('project')
            this.prDetailLineResource.set('work_address', projectResource.attributes.work_addresses[0])

            this.$refs.payrollDetailLines.addLine(this.prDetailLineResource)
        },
        async validate(assignments = []) {
            if (this.calculating === true) return undefined
            this.calculating = true
            let validationResponse = await this.resource.validatePr({ assignments })
            console.log(validationResponse)
            if (!validationResponse.length) {
                await this.calculate({ assignments })
            } else {
                this.selectedAssignments = assignments
                this.validationMessages = validationResponse
            }

            this.calculating = false
        },
        async calculate(assignments = []) {
            this.calculating = true
            await this.resource.calculate({ assignments })
            await this.$refs.payrollDetailLines.load()
            this.calculating = false
        },
    },
})
export class PayrollDetailFeeLineResource extends PayrollDetailLineResource {
    static validation = extendValidators(PayrollDetailLineResource, {
        fee: isRequired('fee'),
    })
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-button class="ml-2" @click="showAddFee">Add Payroll Fees</b-button>
                <b-button class="ml-2" disabled>Add Employee</b-button>
                <b-dropdown ref="calcButton" :disabled="calculating" split boundary="viewport" right class="ml-2" @click="validate">
                    <template #button-content>Calculate <b-spinner v-if="calculating" small /></template>
                    <b-dropdown-item>Select Assignemnts to Calculate</b-dropdown-item>
                </b-dropdown>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndReturnToList">
                    <template #button-content>Save <b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndContinue">Save and Continue Editing</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <resource-card title="Basic Information" class="my-3">
            <b-row>
                <b-col md="6">
                    <resource-edit-form
                        ref="left_info"
                        :resource="resource"
                        :formset="leftFormset"
                        :focus="Object.keys(leftFormset)[0]"
                        size="sm"
                        no-submit
                        @openResource="openResource" />
                </b-col>
                <b-col md="6">
                    <resource-edit-form ref="right_info" :resource="resource" :formset="rightFormset" size="sm" no-submit @openResource="openResource" />
                </b-col>
            </b-row>
        </resource-card>
        <payroll-detail-line-grid ref="payrollDetailLines" :payroll="resource" />
        <b-modal v-model="showPrFeeModal" ok-title="Add" size="xl" @ok="saveNewPrFee">
            <resource-edit-form ref="addFee" :resource="prDetailLineResource" :formset="prFeeFormset" size="sm" no-submit />
        </b-modal>
        <b-modal v-model="validationMessages.length" ok-title="Continue" size="xl" @ok="calculate(selectedAssignments)">
            <div v-for="(message, index) in validationMessages" :key="message">{{ index + 1 }}. {{ message }}</div>
        </b-modal>
    </div>
</template>
