<script>
import BaseDetail from '../BaseDetail'
export default {
    name: 'ClientDetail',
    extends: BaseDetail,
    computed: {
        formsToValidate() {
            return [
                this.$refs.basic_info,
                this.$refs.fees,
                this.$refs.contact_address,
                this.$refs.credit_billing_payments,
                this.$refs.other_client_settings,
                this.$refs.gl_accounts,
                this.$refs.pr_tax_cutoffs,
                this.$refs.wc_billing_cutoffs,
                this.$refs.other_vendor_settings,
                this.$refs.other_customer_settings,
                this.$refs.additional_settings,
            ]
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col md="6">
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form
                            ref="basic_info"
                            :resource="resource"
                            :formset="resource.getFormset(['code', 'parent_client', 'name', 'description', 'is_master'])"
                            focus="code"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Fees" class="my-3">
                        <resource-edit-form
                            ref="fees"
                            :resource="resource"
                            :formset="resource.getFormset(['fees', 'fee_groups'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="Contact & Address" class="my-3">
                        <resource-edit-form
                            ref="contact_address"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'name_legal',
                                    'name_reports',
                                    'payee_name',
                                    'alias_name',
                                    'alias_type',
                                    'address_client_secondaries',
                                    'address_main',
                                    'address_production_office',
                                    'address_mailing',
                                    'address_billing',
                                    'address_contact',
                                    'default_address',
                                ])
                            "
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Credit, Billing & Payments" class="my-3">
                        <resource-edit-form
                            ref="credit_billing_payments"
                            :resource="resource"
                            :formset="resource.getFormset(['ach_tense', 'ach_days', 'ach_account_number', 'ach_routing_number'])"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="Other Client Settings" class="my-3">
                        <resource-edit-form
                            ref="other_client_settings"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'company',
                                    'org_type',
                                    'industry',
                                    'pay_period_ending_day',
                                    'default_payment_type',
                                    'payment_term',
                                    'bank',
                                    'customer_bank',
                                    'allow_direct_deposit',
                                    'pay_frequency',
                                    'printer_ip',
                                    'printer_name',
                                    'psa_agreement_signed_on',
                                    'fee_bundling',
                                    'mpiphp_employer_id',
                                    'mpiphp_contribution_level',
                                    'wga_signatory_number',
                                    'sag_signatory_number',
                                    'dga_signatory_number',
                                    'billable_item',
                                    'separate_allowance_checks',
                                    'sick_accrues_on_parent',
                                    'factored',
                                ])
                            "
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="GL Accounts" class="my-3">
                        <resource-edit-form
                            ref="gl_accounts"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'receivable_account',
                                    'deposit_account',
                                    'account_number',
                                    'default_accounts',
                                    'petty_cash_enabled',
                                    'petty_cash_account',
                                    'petty_cash_custodian',
                                    'custodian_cash_account',
                                    'petty_cash_p_card_enabled',
                                    'petty_cash_p_card_account',
                                ])
                            "
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="PR Tax Cutoffs" class="my-3">
                        <resource-edit-form
                            ref="pr_tax_cutoffs"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'apply_pr_tax_cutoffs_fica_ss',
                                    'apply_pr_tax_cutoffs_futa',
                                    'apply_pr_tax_cutoffs_suta',
                                    'apply_pr_tax_cutoffs_other',
                                    'bill_suta_on_resident_state',
                                ])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="WC Billing & Cutoffs" class="my-3">
                        <resource-edit-form
                            ref="wc_billing_cutoffs"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'apply_wc_cutoffs',
                                    'bill_wc_on_premium_dollars',
                                    'bill_wc_on_allowances',
                                    'bill_wc_on_resident_state',
                                    'bill_wc_on_hire_state',
                                ])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="Other Vendor Settings" class="my-3">
                        <resource-edit-form
                            ref="other_vendor_settings"
                            :resource="resource"
                            :formset="resource.getFormset(['entity_type', 'w9', 'tax_id'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Other Customer Settings" class="my-3">
                        <resource-edit-form
                            ref="other_customer_settings"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'currency',
                                    'sales_rep',
                                    'credit_limit_decimal',
                                    'default_payment_method',
                                    'billing_notification_method',
                                    'comments',
                                ])
                            "
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <resource-card title="Additional Settings" class="my-3">
                        <resource-edit-form
                            ref="additional_settings"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'id',
                                    'apex_company_id',
                                    'delivery_method',
                                    'number',
                                    'phone_cell',
                                    'phone_home',
                                    'updated_on',
                                    'created_on',
                                    'active',
                                ])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
