<script>
import Vue from 'vue'

import BaseDetail from '../../components/BaseDetail'
import VendorResource from '../../components/system/VendorResource'
import PurchaseOrderResource from '../../components/transactions/PurchaseOrderResource'
import AccountsPayableResource from '../../components/transactions/AccountsPayableResource'
import AddressResource from '../../components/system/AddressResource'
import AssignmentResource from '../../components/system/AssignmentResource'
import EmployeeResource from '../../components/system/EmployeeResource'
import BudgetResource from '../../components/system/BudgetResource'
import ProjectResource from '../../components/system/ProjectResource'
import FeeResource from '../../components/system/FeeResource'
import EmployeeDirectDepositRuleResource from '../../components/system/EmployeeDirectDepositRuleResource'
import ClientResource from '../../components/system/ClientResource'
import BankResource from '../../components/system/BankResource'
import BillableTypeResource from '../../components/system/BillableTypeResource'
import ActualFeeRateResource from '../../components/system/ActualFeeRateResource'
import UserResource from '../../components/system/UserResource'
import PayrollResource from '../../components/transactions/PayrollResource'
import APISettingResource from '../../components/system/APISettingResource'
import ReportRenderResource from '../../components/reports/detail'
import SignatureResource from '../../components/system/SignatureResource'

export default Vue.extend({
    name: 'ResourceDetail',
    components: {
        BaseDetail,
        ActualFeeRateResource,
        AddressResource,
        VendorResource,
        PurchaseOrderResource,
        AccountsPayableResource,
        EmployeeResource,
        UserResource,
        AssignmentResource,
        BudgetResource,
        ProjectResource,
        FeeResource,
        EmployeeDirectDepositRuleResource,
        ClientResource,
        BankResource,
        BillableTypeResource,
        PayrollResource,
        APISettingResource,
        ReportRenderResource,
        SignatureResource,
    },

    props: {
        instanceName: {
            type: String,
            required: true,
        },
        resourceClassName: {
            type: String,
            required: true,
        },
        id: {
            type: [Number, String],
            default: null,
        },
        displayAsModal: Boolean,
    },

    computed: {
        attributes: ({ id, instanceName, resourceClassName, displayAsModal }) => ({
            id,
            instanceName,
            resourceClassName,
            displayAsModal,
        }),
        resourceDetailPage: ({ $options, resourceClassName }) => $options.components[`${resourceClassName}`] || BaseDetail,
    },
})
</script>

<template>
    <component :is="resourceDetailPage" ref="detailComponent" v-bind="attributes" @update="$emit('update', $event)" />
</template>
