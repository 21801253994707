<script>
import BaseDetail from '../BaseDetail'

export default {
    name: 'ActualFeeRateDetail',
    extends: BaseDetail,

    computed: {
        formsToValidate() {
            return [this.$refs.basic_info, this.$refs.attach, this.$refs.rate_function, this.$refs.exemptions, this.$refs.protected]
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col md="12">
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form
                            ref="basic_info"
                            :resource="resource"
                            :formset="resource.getFormset(['fee', 'name', 'description', 'active', 'start_date', 'end_date'])"
                            focus="fee"
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
                <b-col md="12">
                    <resource-card title="Attach To" class="my-3">
                        <resource-edit-form
                            ref="attach"
                            :resource="resource"
                            :formset="resource.getFormset(['client', 'project', 'labor_union_local', 'agreement', 'employee', 'assignment'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <resource-card title="Rate Function & Min/Max" class="my-3">
                        <resource-edit-form
                            ref="rate_function"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'rate_function',
                                    'billed_rate_fixed_amount_decimal',
                                    'override_billed_rate_fixed_amount_decimal',
                                    'billed_rate_percentage',
                                    'override_billed_rate_percentage',
                                    'minimum_fee_basis',
                                    'maximum_fee_basis',
                                    'minimum_fee_amount_decimal',
                                    'override_minimum_fee_amount_decimal',
                                    'maximum_fee_amount_decimal',
                                    'override_maximum_fee_amount_decimal',
                                    'maximum_subject_hours',
                                    'override_maximum_subject_hours',
                                    'maximum_subject_wages_amount_decimal',
                                    'override_maximum_subject_wages_amount_decimal',
                                    'flat_subject_hours',
                                    'override_flat_subject_hours',
                                    'lifetime_maximum_amount_decimal',
                                    'override_lifetime_maximum_amount_decimal',
                                    'round_to_nearest',
                                    'override_round_to_nearest',
                                    'round_result',
                                    'override_round_result',
                                    'round_result_method',
                                ])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
                <b-col md="12">
                    <resource-card title="Exemptions" class="my-3">
                        <resource-edit-form
                            ref="exemptions"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'futa_exempt',
                                    'fit_exempt',
                                    'sit_exempt',
                                    'ss_exempt',
                                    'medi_exempt',
                                    'sdi_exempt',
                                    'suta_exempt',
                                    'always_apply_to_premium_dollars',
                                ])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
                <b-col md="12">
                    <resource-card title="Protected Net" class="my-3">
                        <resource-edit-form
                            ref="protected"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'protected_net_calculation_method',
                                    'protected_net_rate_percentage',
                                    'override_protected_net_rate_percentage',
                                    'protected_net_amount_decimal',
                                    'override_protected_net_amount_decimal',
                                ])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
