var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{directives:[{name:"page-title",rawName:"v-page-title",value:(_vm.pageTitle),expression:"pageTitle"}]},[(!_vm.displayAsModal)?_c('page-header',{attrs:{"title":_vm.pageTitle},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('router-link',{attrs:{"to":_vm.goToList}},[_vm._v(_vm._s(_vm.readableResourceClass))]),_vm._v(" | "+_vm._s(_vm.pageAction)+" ")]},proxy:true},{key:"actions-right",fn:function(){return [_c('b-dropdown',{staticClass:"ml-2 user-select-none",attrs:{"disabled":_vm.saving,"split":"","boundary":"viewport","right":""},on:{"click":_vm.saveAndContinue},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v("Save"),(_vm.saving)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()]},proxy:true}],null,false,1912748581)},[_c('b-dropdown-item',{on:{"click":_vm.saveAndAddAnother}},[_vm._v("Save and Add Another")]),_c('b-dropdown-item',{on:{"click":_vm.saveAndReturnToList}},[_vm._v("Save and Return to List")])],1)]},proxy:true}],null,false,850255546)}):_vm._e(),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('resource-card',{staticClass:"my-3",attrs:{"title":"Basic Information"}},[_c('resource-edit-form',{ref:"basic_info",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset([
                                'code',
                                'name',
                                'description',
                                'account_number',
                                'routing_number',
                                'account_fraction',
                                'currency',
                                'default_account_cash',
                                'default_account_clearing',
                                'default_account_deposits',
                                'default_account_discount' ]),"focus":"code","size":"sm","no-submit":""},on:{"openResource":_vm.openResource}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('resource-card',{staticClass:"my-3",attrs:{"title":"Contact"}},[_c('resource-edit-form',{ref:"contact",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset(['contact_name', 'branch_number', 'address_physical', 'address_mailing', 'phone', 'fax', 'email']),"size":"sm","no-submit":""},on:{"openResource":_vm.openResource}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('resource-card',{staticClass:"my-3",attrs:{"title":"Checks"}},[_c('resource-edit-form',{ref:"checks",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset([
                                'check_preferred_template',
                                'check_copies',
                                'check_range_start',
                                'check_range_end',
                                'check_offset_x',
                                'check_offset_y',
                                'eft_range_start',
                                'eft_range_end' ]),"size":"sm","no-submit":""}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('resource-card',{staticClass:"my-3",attrs:{"title":"Positive Pay"}},[_c('resource-edit-form',{ref:"positive_pay",attrs:{"resource":_vm.resource,"formset":_vm.resource.getFormset([
                                'positivepay_host',
                                'positivepay_outbound_path',
                                'positivepay_inbound_path',
                                'positivepay_protocol',
                                'positivepay_cert' ]),"size":"sm","no-submit":""}})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }