<script>
import BaseDetail from '../BaseDetail'

export default {
    name: 'BankDetail',
    extends: BaseDetail,

    computed: {
        formsToValidate() {
            return [this.$refs.basic_info, this.$refs.contact, this.$refs.checks, this.$refs.positive_pay]
        },
    },
}
</script>

<template>
    <div v-if="!loading" v-page-title="pageTitle">
        <page-header v-if="!displayAsModal" :title="pageTitle">
            <template #title>
                <router-link :to="goToList">{{ readableResourceClass }}</router-link> | {{ pageAction }}
            </template>
            <template #actions-right>
                <b-dropdown :disabled="saving" split boundary="viewport" right class="ml-2 user-select-none" @click="saveAndContinue">
                    <template #button-content>Save<b-spinner v-if="saving" small /></template>
                    <b-dropdown-item @click="saveAndAddAnother">Save and Add Another</b-dropdown-item>
                    <b-dropdown-item @click="saveAndReturnToList">Save and Return to List</b-dropdown-item>
                </b-dropdown>
            </template>
        </page-header>
        <b-container fluid>
            <b-row>
                <b-col md="6">
                    <resource-card title="Basic Information" class="my-3">
                        <resource-edit-form
                            ref="basic_info"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'code',
                                    'name',
                                    'description',
                                    'account_number',
                                    'routing_number',
                                    'account_fraction',
                                    'currency',
                                    'default_account_cash',
                                    'default_account_clearing',
                                    'default_account_deposits',
                                    'default_account_discount',
                                ])
                            "
                            focus="code"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Contact" class="my-3">
                        <resource-edit-form
                            ref="contact"
                            :resource="resource"
                            :formset="resource.getFormset(['contact_name', 'branch_number', 'address_physical', 'address_mailing', 'phone', 'fax', 'email'])"
                            size="sm"
                            no-submit
                            @openResource="openResource" />
                    </resource-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <resource-card title="Checks" class="my-3">
                        <resource-edit-form
                            ref="checks"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'check_preferred_template',
                                    'check_copies',
                                    'check_range_start',
                                    'check_range_end',
                                    'check_offset_x',
                                    'check_offset_y',
                                    'eft_range_start',
                                    'eft_range_end',
                                ])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
                <b-col md="6">
                    <resource-card title="Positive Pay" class="my-3">
                        <resource-edit-form
                            ref="positive_pay"
                            :resource="resource"
                            :formset="
                                resource.getFormset([
                                    'positivepay_host',
                                    'positivepay_outbound_path',
                                    'positivepay_inbound_path',
                                    'positivepay_protocol',
                                    'positivepay_cert',
                                ])
                            "
                            size="sm"
                            no-submit />
                    </resource-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
